import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import Button from '@atoms/Button/Button';
import setTrackingCookies from '@utils/setTrackingCookies';
import IconCross from '@svg/icon-cross.svg';
import styles from './CookieBanner.module.scss';

const CookieBanner = () => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [marketingCookies, setMarketingCookies] = useState(true);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const nextRouter = useRouter();

  const save = useCallback(() => {
    setOpen(false);
    setTrackingCookies('cookie-consent-marketing', marketingCookies);
  }, [marketingCookies]);

  useEffect(() => {
    if (!Cookies.get('cookie-consent-analytics')) {
      setOpen(true);
    }

    setTrackingCookies('cookie-consent-analytics');

    nextRouter.events.on('routeChangeStart', () => {
      save();
    });

    return nextRouter.events.off('routeChangeStart', () => {
      save();
    });
  }, [nextRouter.events, save]);

  return open ? (
    <div className={styles.cookies}>
      <div className={styles.container}>
        <div className={styles.text}>
          <p>
            {t('cookies.text')}{' '}
            <button
              className="buttonLink"
              type="button"
              onClick={() => {
                setSettingsOpen(prev => !prev);
              }}
            >
              {t('cookies.settingsOpen')}
            </button>
          </p>
          <button className={styles.close} type="button" onClick={save}>
            <IconCross />
          </button>
        </div>
        {settingsOpen && (
          <div className={styles.settings}>
            <div className={styles.item}>
              <label className={styles.label} htmlFor="analytical">
                <input
                  className={styles.input}
                  type="checkbox"
                  id="analytical"
                  name="analytical"
                  disabled
                  checked
                />
                {t('cookies.analytical')}
              </label>
            </div>
            <div className={styles.item}>
              <label className={styles.label} htmlFor="marketing">
                <input
                  className={styles.input}
                  type="checkbox"
                  id="marketing"
                  name="marketing"
                  checked={marketingCookies}
                  onChange={() => {
                    setMarketingCookies(prev => !prev);
                  }}
                />
                {t('cookies.marketing')}
              </label>
            </div>

            <div className={styles.save}>
              <Button as="button" click={save} type="button">
                {t('cookies.save')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default CookieBanner;
