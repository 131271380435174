import React from 'react';
import PropTypes from 'prop-types';
import styles from './DefaultLayout.module.scss';

const DefaultLayout = ({ children }) => (
  <div className={styles.content}>{children}</div>
);

export default DefaultLayout;

DefaultLayout.propTypes = {
  children: PropTypes.node,
};
