import Cookies from 'js-cookie';

const setTrackingCookies = (name, on = true) => {
  Cookies.set(name, on ? '1' : '0', {
    expires: 365,
    secure: process.env.NODE_ENV === 'production',
  });

  if (typeof window !== 'undefined' && window && window.dataLayer) {
    window.dataLayer.push({ event: name });
  }
};

export default setTrackingCookies;
