import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import Favicons from '@atoms/Favicons/Favicons';
import NProgress from '@atoms/NProgress/NProgress';
import '@atoms/NProgress/NProgress.scss';
import '@styles/_base.scss';
import CookieBanner from '@organisms/CookieBanner/CookieBanner';
import Footer from '@organisms/Footer/Footer';
import DefaultLayout from '@layouts/DefaultLayout/DefaultLayout';

const App = ({ Component, pageProps = {} }) => {
  const { websiteSettings } = pageProps;

  // This is the solution to prevent the "flickering" of colors when setting theme color.
  // This injects a script at the top of the body that will block rendering
  // and set the root css values first. Read more: https://www.joshwcomeau.com/react/dark-mode/
  const ThemeScriptTag = () => {
    const codeToRunOnClient = `
      (function() {
        const root = document.documentElement;

        if ('${websiteSettings.font}') {
          root.style.setProperty(
            '--font-family-base', '${websiteSettings.font}'
          );
        }

        if ('${websiteSettings.brandColor}') {
          root.style.setProperty(
            '--color-brand', '${websiteSettings.brandColor}'
          );
        }

        if ('${websiteSettings.buttonTextColor}') {
          root.style.setProperty(
            '--color-button-text', '${websiteSettings.buttonTextColor}'
          );
        }
      })()
    `;
    // eslint-disable-next-line react/no-danger
    return <script dangerouslySetInnerHTML={{ __html: codeToRunOnClient }} />;
  };

  return (
    <>
      {websiteSettings && <ThemeScriptTag />}
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        {websiteSettings?.favicon ? (
          <link rel="shortcut icon" href={websiteSettings?.favicon} />
        ) : (
          <Favicons />
        )}
        <title>deBanensite.nl</title>
      </Head>
      <NProgress />
      <DefaultLayout>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...pageProps} />
      </DefaultLayout>
      <Footer />
      <CookieBanner />
    </>
  );
};

export default appWithTranslation(App);

App.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
};
