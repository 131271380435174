import React from 'react';
import { useTranslation } from 'next-i18next';
import LogoDeBanenSite from '@svg/logo.svg';
import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <a
          className={styles.privacy}
          href={`${process.env.DEBANENSITE_FRONTEND_ENDPOINT}/privacy`}
        >
          {t('footer.privacy')}
        </a>
        <div className={styles.poweredBy}>
          {t('footer.poweredBy')}{' '}
          <a href={process.env.DEBANENSITE_FRONTEND_ENDPOINT}>
            <LogoDeBanenSite />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
